//===============用于创建VUEX的store
import { createStore } from "vuex";
import userInfo from "./userInfo";

//创建store
export default createStore({
  // //准备actions -- 用于相应组件中的动作
  // actions: {},
  // //准备mutations -- 用于操作数据（state）
  // mutations: {},
  // //准备state -- 用于存储数据
  // state: {},
  state: {
    myValue: "",
    myImgValue: "",
    childData: null,
    fileImgList: null,
    loading:false,
    isSubmitButtonClicked: true,
    isSame_Nid:'Y',
    isSame_Law:'N',
    otherSchool:['','','','','','','','','','','','','','']
  },
  mutations: {
    SETOTHERSCHOOL(state, {index,value:newValue}) {
      
      if(index >= 0 && index <state.otherSchool.length){
      state.otherSchool[index] = newValue;

    }
  },
    SETISSAMENID(state, newValue) {
      state.isSame_Nid = newValue;
    },
    SETISSAMELAW(state, newValue) {
      state.isSame_Law = newValue;
    },
    setMyValue(state, newValue) {
      state.myValue = newValue;
    },
    setMyImgValue(state, newValue) {
      state.myImgValue = newValue;
    },
    setChildData(state, data) {
      state.childData = data;
    },
    setFileData(state, data) {
      state.fileImgList = data;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading
    },

    setSubmitButtonClicked(state, value) {
      console.log("mutation:",value);
      
      state.isSubmitButtonClicked = value;
    }
  },
  actions: {
    setOtherSchool({ commit }, value) {
      commit("SETOTHERSCHOOL",  value);
    },
    setIsSameNid({ commit }, value) {
      commit("SETISSAMENID", value);
    },
    setIsSameLaw({ commit }, value) {
      commit("SETISSAMELAW", value);
    },
    storeValue({ commit }, value) {
      commit("setMyValue", value);
    },
    storeImgValue({ commit }, value) {
      commit("setMyImgValue", value);
    },
    storeImgFileValue({ commit }, value) {
      commit("setFileData", value);
      
    },
    setLoading({ commit }, isLoading) {
      commit('setLoading', isLoading)
    },
    setButtonValue({ commit }, value) {
      console.log("action:",value);
      
      commit('setSubmitButtonClicked', value)
    },
  },
  getters:{
    isLoading(state) {
      return state.loading
    }
  },

  modules: {
    userInfo,
  },
});
