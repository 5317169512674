<template>
  <!-- <el-input type="text" readonly hidden/>用于存序号，需不需要这种方式存还待定-->
  <a-tabs
    v-model:activeKey="activeKey"
    type="editable-card"
    @edit="onEdit"
    @change="handleTabChange"
  >
    <a-tab-pane
      v-for="(pane, ind) in panes"
      :key="pane.key"
      :tab="pane.title"
      :closable="pane.closable"
    >
      <form class="form">
        <div class="input-box">
          <span class="span-tit">*奖惩名称</span>
          <el-input
            type=""
            v-model="resLogin.form['reward-punishment'][ind].DC_RANDPNAME"
            maxlength="30"
            required
          />
          <span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">*奖惩类别</span>
          <el-select
            placeholder="请选择"
            v-model="resLogin.form['reward-punishment'][ind].DC_RANDPCATE"
            clearable
          >
            <el-option
              :value="option.fieldvalue"
              v-for="(option, index) in selectList.randpcate"
              :key="index"
              :label="option.xlatshortname"
            >
            </el-option>
          </el-select>
          <span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">*奖惩级别</span>
          <el-select
            placeholder="请选择"
            v-model="resLogin.form['reward-punishment'][ind].DC_RANDPLV"
            clearable
          >
            <el-option
              :value="option.fieldvalue"
              v-for="(option, index) in selectList.randplvl"
              :key="index"
              :label="option.xlatshortname"
            >
            </el-option>
          </el-select>
          <span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">*奖惩对象</span>
          <el-select
            placeholder="请选择"
            v-model="resLogin.form['reward-punishment'][ind].DC_RANDPTARGET"
            clearable
          >
            <el-option
              :value="option.fieldvalue"
              v-for="(option, index) in selectList.randptarget"
              :key="index"
              :label="option.xlatshortname"
            >
            </el-option>
          </el-select>
          <span class="descr"></span>
        </div>

        <div class="input-box">
          <span class="span-tit">公文号</span
          ><el-input
            type=""
            v-model="resLogin.form['reward-punishment'][ind].DC_ODN"
          /><span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">发文日期</span>
          <el-input
            type="date"
            v-model="resLogin.form['reward-punishment'][ind].DC_PUBDATE"
          />
          <span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">*奖惩事由</span>
          <el-input
            type="text"
            v-model="resLogin.form['reward-punishment'][ind].DC_COMMENTS"
            maxlength="30"
          />
          <span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">奖惩形式</span>
          <el-input
            type="text"
            v-model="resLogin.form['reward-punishment'][ind].DC_RANDPFORMS"
            maxlength="30"
          />
          <span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">*奖惩币种</span>

          <!-- <el-autocomplete
            v-model="searchText[tabsIndex]"
            :fetch-suggestions="querySearch"
            class="inline-input w-50"
            placeholder="支持输入查询"
            @select="handleSelect(ind, $event)"
            :value-key="value"
            submit-on-enter
            clearable
          /> -->
          <el-select
            placeholder="请选择"
            v-model="resLogin.form['reward-punishment'][ind].CURRENCY_CD"
            clearable
          >
            <el-option
              :value="option.currency_cd"
              v-for="(option, index) in selectList.currency_list"
              :key="index"
              :label="option.descr"
              :default="cny"
            >
            </el-option>
          </el-select>
          <span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">奖惩执行日期</span>
          <el-input
            type="date"
            v-model="resLogin.form['reward-punishment'][ind].DC_RANDPDATE"
          />
          <span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">奖惩金额</span>
          <el-input
            type="text"
            v-model="resLogin.form['reward-punishment'][ind].DC_RANDPAMOUNT"
            maxlength="30"
          />
          <span class="descr"></span>
        </div>
      </form>
    </a-tab-pane>
  </a-tabs>
</template>
<script>
import { useStore } from "vuex";
import { ref, onMounted } from "vue";
import { computed } from "vue";
import { provide } from "vue";
import { common } from "../mixin/common";

export default {
  name: "family",
  props: {},
  mixins: [common],
  setup() {
    // const valueToExpose = '1111';
    // provide('data1', valueToExpose);
    const cny = "CNY"
    const searchText = ref([]);
    const panes = ref([]);
    const store = useStore();
    //取值state
    const resLogin = computed(() => {
      return store.state.userInfo.resLogin;
    });
    //取值state
    const selectList = computed(() => {
      return store.state.myValue;
    });

    // layui标签页
    let index = 1; //奖惩信息编号
    // ============根据返回的数据看数组的长度在页面上遍历页签，空数组的话就自动生成一个页签==
    if (resLogin.value.form["reward-punishment"].length == 0) {
      panes.value.push({
        title: `奖惩信息${index}`,
        content: "",
        key: `${index}`,
      });
      index = index + 1;
    } else {
      //遍历所有数据，有几条数据就生成几个tab
      for (
        let i = 0;
        i < resLogin.value.form["reward-punishment"].length;
        i++
      ) {
        if(i==0){
        panes.value.push({
          title: `奖惩信息${index}`,
          content: "",
          key: `${index}`,
          closable:false

        });
      }else{
        panes.value.push({
          title: `奖惩信息${index}`,
          content: "",
          key: `${index}`,
        });

      }
        index = index + 1;
      }
    }
    //=================

    const activeKey = ref(panes.value[0].key);
    const newTabIndex = ref(0);
    //添加页签
    const add = () => {
      let reward_punishment = {
        DC_CANDDT_ID: resLogin.value.form["base-info"].DC_CANDDT_ID,
        SEQNO: "",
        DC_RANDPNAME: "",
        DC_RANDPCATE: "",
        DC_RANDPLV: "",
        DC_RANDPTARGET: "",
        DC_ODN: "",
        DC_PUBDATE: "",
        DC_COMMENTS: "",
        DC_RANDPFORMS: "",
        CURRENCY_CD: "",
        DC_RANDPDATE: "",
        DC_RANDPAMOUNT: "",
      };
      activeKey.value = index;
      panes.value.push({
        title: `奖惩信息${index}`,
        content: "",
        key: activeKey.value,
      });
      resLogin.value.form["reward-punishment"].push(reward_punishment);
      console.log(resLogin.value.form["reward-punishment"]);
      index = index + 1;
      // resLogin.value.form["relative"][index].DEPENDENT_BENEF = `${index}`;
      store.dispatch("userInfo/setUserData", resLogin);
    };
    //删除页签
    const remove = (targetKey) => {
      let lastIndex = 0;
      panes.value.forEach((pane, i) => {
        if (pane.key === targetKey) {
          lastIndex = i - 1;
        }
      });
      panes.value = panes.value.filter((pane) => pane.key !== targetKey);
      if (panes.value.length && activeKey.value === targetKey) {
        if (lastIndex >= 0) {
          activeKey.value = panes.value[lastIndex].key;
        } else {
          activeKey.value = panes.value[0].key;
        }
      }
      index = index - 1;
      resLogin.value.form["reward-punishment"].splice(targetKey - 1, 1);

      store.dispatch("userInfo/setUserData", resLogin);
    };
    const onEdit = (targetKey, action) => {
      console.log("on", targetKey);

      if (action === "add") {
        add();
      } else {
        remove(targetKey);
      }
    };
    store.commit("setChildData", resLogin.value.form);

    const tabsIndex = ref(0);
    const handleTabChange = (key, event) => {
      tabsIndex.value = key - 1;
      // selectData();
      getData();
    };
    const getData = () => {
      selectList.value.currency_list.filter((i) => {
        if (
          i.currency_cd ==
          resLogin.value.form["reward-punishment"][tabsIndex.value].CURRENCY_CD
        ) {
          searchText.value[tabsIndex.value] = i.descr;
        }
      });
    };
    onMounted(() => {
      getData();
      selectList.value.currency_list.forEach((ele) => {
        ele.value = ele.descr;
      });
      restaurants.value = loadAll();
    });
    const restaurants = ref([{}]);
    const querySearch = (queryString, cb) => {
      const results = queryString
        ? restaurants.value.filter((option) =>
            option.value.includes(queryString)
          )
        : restaurants.value;
      cb(results);
    };
    const loadAll = () => {
      return selectList.value.currency_list;
    };
    const handleSelect = (index, item) => {
      console.log(item, "======", index);
      searchText.value[index] = item.value;
      resLogin.value.form["reward-punishment"][index].CURRENCY_CD =
        item.currency_cd;
    };
    return {
      resLogin,
      index,
      add,
      remove,
      onEdit,
      panes,
      selectList,
      restaurants,
      querySearch,
      loadAll,
      handleSelect,
      searchText,
      getData,
      handleTabChange,
      cny
    };
    // console.log(store.state.userInfo.resLogin,"resLogin")
  },
};
</script>
<style scoped>
/* 自己的样式 */

.form {
  font-size: var(--pc-text-size-title);
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  line-height: 45px;
  padding: 15px;
  /* padding-right: 50px; */

  .file-box {
    width: 100%;
  }

  .input-box {
    /* min-width: 100px; */
    width: calc(100% / 3);
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 3px 0 3px 0;

    .span-tit {
      width: 150px;
      /* display: flex;
      justify-content: end; */
      line-height: 17px;
    }

    input,
    select {
      width: 200px;
      border: 1px solid rgb(199, 199, 199);
      height: 25px;
      border-radius: 3px;
      font-size: 12px;
      margin-left: 5px;

      /* -webkit-appearance:unset !important ; */
      option {
        height: 25px;
      }
    }

    .descr {
      width: 195px;
      word-wrap: break-word;
      line-height: 17px;
      margin-left: 5px;
    }
  }
}

@media only screen and (max-width: 768px) {
  /* 手机端适配 */
  .form {
    gap: 10px;

    .input-box {
      gap: 10px;
      width: 100%;

      .span-tit {
        width: 40%;
      }

      input,
      select {
        width: 80%;
        height: 20px;
        font-size: 10px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  /* 平板电脑的样式 */
}
</style>
