import { createRouter, createWebHistory } from "vue-router";

//引入组件
import all from "../components/all.vue";
import login from "../components/login.vue";
import addressInfo from "../pages/addressInfo";
import basicInfo from "../pages/basicInfo";
import beforeWork from "../pages/beforeWorkInfo";
import education from "../pages/educationInfo";
import family from "../pages/familyInfo";
// import file from "../pages/fileInfo";
import jobQualifcation from "../pages/jobQualification";
import language from "../pages/languageInfo";
import rp from "../pages/rpInfo";
import userBank from "../pages/userBankInfo";
import jobTitle from "../pages/jobTitileInfo.vue";
import refuse from "../components/refuse.vue";
import store from "@/store/index";
import fileInfo from '../pages/fileInfo.vue'
import error from '../components/error.vue'

//创建一个路由器
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/login",
      component: login,
    },
    {
      path: "/all",
      component: all,
      // redirect:"/all/basicInfo",
      children: [
        {
          path: "/all/address",
          component: addressInfo,
          meta: {
            keepAlive: true, // 设置该路由对应的组件需要被缓存
          },
        },
        {
          path: "/all/basicInfo",
          component: basicInfo,
          meta: {
            keepAlive: true, // 设置该路由对应的组件需要被缓存
          },
        },
        {
          path: "/all/beforeWork",
          component: beforeWork,
          meta: {
            keepAlive: true, // 设置该路由对应的组件需要被缓存
          },
        },
        {
          path: "/all/education",
          component: education,
          meta: {
            keepAlive: true, // 设置该路由对应的组件需要被缓存
          },
        },
        {
          path: "/all/family",
          component: family,
          meta: {
            keepAlive: true, // 设置该路由对应的组件需要被缓存
          },
        },
        {
          path: "/all/jobQualifcation",
          component: jobQualifcation,
          meta: {
            keepAlive: true, // 设置该路由对应的组件需要被缓存
          },
        },
        {
          path: "/all/jobTitle",
          component: jobTitle,
          meta: {
            keepAlive: true, // 设置该路由对应的组件需要被缓存
          },
        },
        {
          path: "/all/language",
          component: language,
          meta: {
            keepAlive: true, // 设置该路由对应的组件需要被缓存
          },
        },
        {
          path: "/all/rp",
          component: rp,
          meta: {
            keepAlive: true, // 设置该路由对应的组件需要被缓存
          },
        },
        {
          path: "/all/userBank",
          component: userBank,
          meta: {
            keepAlive: true, // 设置该路由对应的组件需要被缓存
          },
        },
        {
          path: "/all/attach",
          component: fileInfo,
          meta: {
            keepAlive: true, // 设置该路由对应的组件需要被缓存
          },
        },
      ],
    },
    {
      path: "/refuse",
      component: refuse,
    },
    {
      path: "/error",
      component: error,
    },
    { path: "", redirect: "/login" },
  ],
});
// let isPageRefreshed = false;

// window.addEventListener('beforeunload', () => {
//   isPageRefreshed = false;
// });

// window.addEventListener('load', () => {
//   isPageRefreshed = true;
// });

// router.beforeEach((to, from, next) => {
//   if (isPageRefreshed && to.path!== '/login') {
//     next('/login');
//   } else {
//     next();
//   }
// });

router.beforeEach((to, from, next) => {
  
  // 从当前的 URL 中获取 query 参数
  const queryParams = new URLSearchParams(window.location.search);
  const source = queryParams.get("DC_CANDDT_ID");

  // 如果 source 参数存在，则将它传递给 login 页面
  if (source) {
    console.log('连接：',source);
    
    to.query.source = source;
  } else {
    console.log("没有参数！",source);
  }
  store.dispatch("setLoading", true);
  next();
});

router.afterEach(() => {
  store.dispatch("setLoading", false); // 结束 loading
});

export default router;
