<template>
  <!-- <el-input type="text" readonly hidden/>用于存序号，需不需要这种方式存还待定-->
  <a-tabs
    v-model:activeKey="activeKey"
    type="editable-card"
    @edit="onEdit"
    @change="handleTabChange"
  >
    <a-tab-pane
      v-for="(pane, ind) in panes"
      :key="pane.key"
      :tab="pane.title"
      :closable="pane.closable"
    >
      <form class="form">
        <div class="input-box">
          <span class="span-tit">*职称名称</span
          ><el-input
            type="text"
            maxlength="30"
            v-model="resLogin.form['job-title'][ind].DC_TITLE_NAME"
          /><span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">*职称类别</span>
          <el-select
           placeholder="请选择"
            v-model="resLogin.form['job-title'][ind].DC_TITLE_TYPE"
            clearable
          >
            <el-option
              :value="option.fieldvalue"
              v-for="(option, index) in selectList.title_type"
              :key="index"
              :label="option.xlatshortname"
            >
            </el-option>
          </el-select>
          <span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">*职称级别</span>
          <el-select
           placeholder="请选择"
            v-model="resLogin.form['job-title'][ind].DC_TITLE_LEVEL"
            clearable
          >
            <el-option
              :value="option.fieldvalue"
              v-for="(option, index) in selectList.title_level"
              :key="index"
              :label="option.xlatshortname"
            >
            </el-option></el-select
          ><span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">*证书编号</span>
          <el-input
            type="text"
            v-model="resLogin.form['job-title'][ind].DC_CERT_NUM"
            maxlength="30"
          />
          <span class="descr"></span>
        </div>

        <div class="input-box">
          <span class="span-tit">*发证时间</span
          ><el-input
            type="date"
            v-model="resLogin.form['job-title'][ind].DC_ISS_DATE"
          /><span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">*发证单位</span>
          <el-input
            type="text"
            maxlength="60"
            v-model="resLogin.form['job-title'][ind].DC_ISS_UNIT"
          />
          <span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">评审方式</span>
          <el-select
            placeholder="请选择"
            v-model="resLogin.form['job-title'][ind].DC_REVW_MTD"
            clearable
          >
            <el-option
              :value="option.fieldvalue"
              v-for="(option, index) in selectList.revw_mtd"
              :key="index"
              :label="option.xlatshortname"
            >
            </el-option>
          </el-select>
          <span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">备注</span>
          <el-mention
            maxlength="254"
            type="textarea"
            style="resize: horizontal"
            v-model="resLogin.form['job-title'][ind].COMMENTS_256"
          ></el-mention>
          <span class="descr"></span>
        </div>

        <!-- <a-upload
          @remove="handleRemove"
          :multiple="true"
          :customRequest="customRequest"
          @change="handleChange"
          :before-upload="beforeUpload"
          :max-count="1"
          :file-list="fileList"
        > -->

        
      </form>
    </a-tab-pane>
  </a-tabs>
</template>
<script>
import { useStore } from "vuex";
import { ref, onMounted } from "vue";
import { computed, watch } from "vue";
import { common } from "../mixin/common";
import { request } from "../mixin/axios";
import { ElMessage, ElMessageBox } from "element-plus";

export default {
  name: "family",
  props: {},
  mixins: [common],
  setup() {


    const panes = ref([]);
    const store = useStore();
    const descr = ref("");

    //取值state
    const resLogin = computed(() => {
      return store.state.userInfo.resLogin;
    });
    //取值state
    const selectList = computed(() => {
      return store.state.myValue;
    });

    const tabsIndex = ref(0);
    const handleTabChange = (key, event) => {
      tabsIndex.value = key - 1;
      // selectData();
    };

    // layui标签页
    let index = 1; //职称信息编号
    // ============根据返回的数据看数组的长度在页面上遍历页签，空数组的话就自动生成一个页签==
    if (resLogin.value.form["job-title"].length == 0) {
      panes.value.push({
        title: `职称信息${index}`,
        content: "",
        key: `${index}`,
      });
      index = index + 1;
    } else {
      //遍历所有数据，有几条数据就生成几个tab
      for (let i = 0; i < resLogin.value.form["job-title"].length; i++) {
        if(i==0){
        panes.value.push({
          title: `职称信息${index}`,
          content: "",
          key: `${index}`,
          closable:false

        });
      }else{
        panes.value.push({
          title: `职称信息${index}`,
          content: "",
          key: `${index}`,
        });

      }
        index = index + 1;
      }
    }
    //=================

    const activeKey = ref(panes.value[0].key);
    const newTabIndex = ref(0);
    //添加页签
    const add = () => {
      let job_title = {
        DC_CANDDT_ID: resLogin.value.form["base-info"].DC_CANDDT_ID,
        SEQNBR: "",
        DC_TITLE_NAME: "",
        DC_TITLE_TYPE: "",
        DC_TITLE_LEVEL: "",
        DC_CERT_NUM: "",
        DC_ISS_DATE: "",
        DC_ISS_UNIT: "",
        DC_REVW_MTD: "",
        COMMENTS_256: "",
      };
      activeKey.value = index;
      panes.value.push({
        title: `职称信息${index}`,
        content: "",
        key: activeKey.value,
      });
      resLogin.value.form["job-title"].push(job_title);
      console.log(resLogin.value.form["job-title"]);
      index = index + 1;
      // resLogin.value.form["relative"][index].DEPENDENT_BENEF = `${index}`;
      store.dispatch("userInfo/setUserData", resLogin);
    };
    //删除页签
    const remove = (targetKey) => {
      let lastIndex = 0;
      panes.value.forEach((pane, i) => {
        if (pane.key === targetKey) {
          lastIndex = i - 1;
        }
      });
      panes.value = panes.value.filter((pane) => pane.key !== targetKey);
      if (panes.value.length && activeKey.value === targetKey) {
        if (lastIndex >= 0) {
          activeKey.value = panes.value[lastIndex].key;
        } else {
          activeKey.value = panes.value[0].key;
        }
      }
      index = index - 1;
      resLogin.value.form["job-title"].splice(targetKey - 1, 1);

      store.dispatch("userInfo/setUserData", resLogin);
    };
    const onEdit = (targetKey, action) => {
      console.log("on", targetKey);

      if (action === "add") {
        add();
      } else {
        remove(targetKey);
      }
    };
    onMounted(() => {
    });

    store.commit("setChildData", resLogin.value.form);

    console.log(resLogin.value.form["job-title"], "------------------");
    //删除附件
    const file_file = ref("");
    const delFile = () => {
      //........
    };
    return {
      resLogin,
      index,
      add,
      remove,
      onEdit,
      panes,
      file_file,
      delFile,
      selectList,
      descr,
      handleTabChange,
    };
    // console.log(store.state.userInfo.resLogin,"resLogin")
  },
};
</script>
<style scoped>
/* 自己的样式 */
.span-tit {
  width: 150px;
  /* display: flex;
  justify-content: end; */
  line-height: 17px;
}
.descr {
  width: 195px;
  word-wrap: break-word;
  line-height: 17px;
  margin-left: 5px;
}

.form {
  font-size: var(--pc-text-size-title);
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  line-height: 45px;
  padding: 15px;
  /* padding-right: 50px; */

  .file-box {
    width: 100%;
  }

  .input-box {
    min-width: 100px;
    width: calc(100% / 3);
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 3px 0 3px 0;

    input,
    select {
      width: 200px;
      border: 1px solid rgb(199, 199, 199);
      height: 25px;
      border-radius: 3px;
      font-size: 12px;
      margin-left: 5px;

      /* -webkit-appearance:unset !important ; */
      option {
        height: 25px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  /* 手机端适配 */
  .form {
    gap: 10px;

    .input-box {
      gap: 10px;
      width: 100%;

      .span-tit {
        width: 40%;
      }

      input,
      select {
        width: 80%;
        height: 20px;
        font-size: 10px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  /* 平板电脑的样式 */
}
</style>
