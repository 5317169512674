<template>
  <form class="form">
    <div style="width: 100%; color: red">提醒：复姓请修改姓名</div>
    <div class="input-box">
      <span class="span-tit">*姓氏</span
      ><el-input
        class="input"
        type="text"
        v-model="resLogin.form['base-info'].LAST_NAME"
        name="LAST_NAME"
        maxlength="30"
        placeholder="请输入"
      /><span class="descr"></span>
    </div>
    <div class="input-box">
      <span class="span-tit">*名字</span
      ><el-input
        class="input"
        type="text"
        v-model="resLogin.form['base-info'].FIRST_NAME"
        name="FIRST_NAME"
        placeholder="请输入"
      /><span class="descr"></span>
    </div>
    <div class="input-box">
      <span class="span-tit">*姓名拼音</span
      ><el-input
        class="input"
        type="text"
        v-model="resLogin.form['base-info'].PREF_FIRST_NAME"
        name="PREF_FIRST_NAME"
        placeholder="请输入"
      /><span class="descr"></span>
    </div>
    <div class="input-box">
      <span class="span-tit">英文名</span
      ><el-input
        class="input"
        type="text"
        v-model="resLogin.form['base-info'].PREF_LAST_NAME"
        name="PREF_LAST_NAME"
        placeholder="请输入"
      /><span class="descr"></span>
    </div>
    <div class="input-box">
      <span class="span-tit">曾用名</span>
      <el-input
        class="input"
        type="text"
        v-model="resLogin.form['base-info'].PREF_SEC_LAST_NAME"
        name="PREF_SEC_LAST_NAME"
        placeholder="请输入"
      /><span class="descr"></span>
    </div>
    <div class="input-box">
      <span class="span-tit">*证件类型</span>
      <el-select
        v-model="resLogin.form['base-info'].NATIONAL_ID_TYPE"
        placeholder="请选择"
        clearable
      >
        <el-option
          :value="option.national_id_type"
          v-for="(option, index) in selectList.nid_type_list"
          :key="index"
          :label="option.descr"
        >
        </el-option
      ></el-select>
      <span class="descr"></span>
    </div>
    <div class="input-box">
      <span class="span-tit">*证件号码</span
      ><el-input
        class="input"
        type="text"
        v-model="resLogin.form['base-info'].NATIONAL_ID"
        name="NATIONAL_ID"
        maxlength="20"
        placeholder="请输入"
      /><span class="descr"></span>
    </div>
    <div class="input-box">
      <span class="span-tit">*是否长期有效</span
      ><el-select
        v-model="resLogin.form['base-info'].YESNO_DROPDOWN"
        name="TESNO_DROPDOWN"
        placeholder="请选择"
        clearable
      >
        <el-option
          :value="option.fieldvalue"
          v-for="(option, index) in selectList.nid_yesno_list"
          :key="index"
          :label="option.xlatshortname"
        >
        </el-option></el-select
      ><span class="descr"></span>
    </div>
    <div class="input-box">
      <span class="span-tit">*证件有效期起</span
      ><el-input
        class="input"
        type="date"
        v-model="resLogin.form['base-info'].START_DT"
        name="START_DT"
        placeholder="请输入"
      /><span class="descr"></span>
    </div>
    <!-- <div class="input-box">
      <span class="span-tit">日期测试</span
      ><el-input
        class="input"
        type="date"

        name="s"
        placeholder="请输入"
      /><span class="descr"></span>
    </div> -->
    <div
      class="input-box"
      v-if="resLogin.form['base-info'].YESNO_DROPDOWN == 'N'"
    >
      <span class="span-tit">证件有效期止</span
      ><el-input
        class="input"
        type="date"
        v-model="resLogin.form['base-info'].END_DT"
        name=""
        placeholder="请输入"
      /><span class="descr"></span>
    </div>
    <div class="input-box">
      <span class="span-tit">出生日期</span
      ><el-input
        class="input"
        v-model="resLogin.form['base-info'].BIRTHDATE"
        type="date"
        name=""
        placeholder="请输入"
      /><span class="descr"></span>
    </div>
    <div class="input-box">
      <span class="span-tit">*性别</span
      ><el-select
        v-model="resLogin.form['base-info'].SEX"
        placeholder="请选择"
        clearable
      >
        <el-option
          :value="option.fieldvalue"
          v-for="(option, index) in selectList.sex_list"
          :key="index"
          :label="option.xlatshortname"
        >
        </el-option></el-select
      ><span class="descr"></span>
    </div>
    <div class="input-box">
      <span class="span-tit">*国籍</span
      ><el-select
        v-model="resLogin.form['base-info'].BIRTHCOUNTRY"
        placeholder="请选择"
        clearable
      >
        <el-option
          :value="option.country"
          v-for="(option, index) in selectList.country"
          :key="index"
          :label="option.descr"
        >
        </el-option></el-select
      ><span class="descr"></span>
    </div>
    <div class="input-box">
      <span class="span-tit">管理区域</span>
      <!-- <el-select v-model="resLogin.form['base-info'].REG_REGION" placeholder="请选择"> -->
      <el-select
        v-model="resLogin.form['base-info'].REG_REGION"
        placeholder="请选择"
        clearable
      >
        <el-option
          :value="option.country"
          v-for="(option, index) in selectList.region"
          :key="index"
          :label="option.descr50"
          :default="chn"
        >
        </el-option></el-select
      ><span class="descr"></span>
    </div>
    <div class="input-box">
      <span class="span-tit">民族</span
      ><el-select
        v-model="resLogin.form['base-info'].ETHNIC_GRP_CD"
        placeholder="请选择"
        clearable
      >
        <el-option
          :value="option.ethnic_grp_cd"
          v-for="(option, index) in selectList.ethnic"
          :key="index"
          :label="option.descr50"
        >
        </el-option></el-select
      ><span class="descr"></span>
    </div>
    <div class="input-box">
      <span class="span-tit">*个人邮箱</span
      ><el-input
        class="input"
        type="email"
        v-model="resLogin.form['base-info'].EMAIL_ADDR"
        name=""
        maxlength="70"
        placeholder="请输入邮箱"
      /><span class="descr"></span>
    </div>
    <div class="input-box">
      <span class="span-tit">*手机号码</span
      ><el-input
        class="input"
        type="text"
        v-model="resLogin.form['base-info'].PHONE"
        name=""
        maxlength="24"
        placeholder="请输入手机号"
      /><span class="descr"></span>
    </div>
    <div class="input-box">
      <span class="span-tit">*首次参加工作时间</span
      ><el-input
        class="input"
        type="date"
        v-model="resLogin.form['base-info'].START_DT_CHN"
        name=""
      /><span class="descr"></span>
    </div>
    <div class="input-box">
      <span class="span-tit">*最高教育程度</span>
      <el-select
        v-model="resLogin.form['base-info'].HIGHEST_EDUC_LVL"
        placeholder="请选择"
        clearable
      >
        <el-option
          :value="option.fieldvalue"
          v-for="(option, index) in selectList.highest_educ_lvl_list"
          :key="index"
          :label="option.xlatshortname"
        >
          {{ option.xlatshortname }}
        </el-option>
      </el-select>
      <span class="descr"></span>
    </div>
    <div class="input-box">
      <span class="span-tit">*婚姻状况</span>
      <el-select
        class="select"
        v-model="resLogin.form['base-info'].MAR_STATUS"
        placeholder="请选择"
        clearable
      >
        <el-option
          :value="option.fieldvalue"
          v-for="(option, index) in selectList.mar_status_list"
          :key="index"
          :label="option.xlatshortname"
        >
        </el-option>
      </el-select>
      <span class="descr"></span>
    </div>
    <div
      class="input-box"
      v-if="
        resLogin.form['base-info'].MAR_STATUS != 'A' &&
        resLogin.form['base-info'].MAR_STATUS != 'O'
      "
    >
      <span class="span-tit">*婚姻状况登记日期</span
      ><el-input
        class="input"
        type="date"
        name=""
        v-model="resLogin.form['base-info'].MAR_STATUS_DT"
      /><span class="descr"></span>
    </div>
    <div class="input-box">
      <span class="span-tit">*是否在校生</span>
      <el-select
        v-model="resLogin.form['base-info'].FT_STUDENT"
        placeholder="请选择"
        clearable
      >
        <el-option
          :value="option.fieldvalue"
          v-for="(option, index) in isList"
          :key="index"
          :label="option.xlatshortname"
        >
        </el-option>
      </el-select>
      <span class="descr"></span>
    </div>
    <div class="input-box">
      <span class="span-tit">*是否留学生</span
      ><el-select
        v-model="resLogin.form['base-info'].DC_INTL_STD"
        placeholder="请选择"
        clearable
      >
        <el-option
          :value="option.fieldvalue"
          v-for="(option, index) in isList"
          :key="index"
          :label="option.xlatshortname"
        >
        </el-option></el-select
      ><span class="descr"></span>
    </div>
    <div class="input-box" v-if="resLogin.form['base-info'].DC_INTL_STD == 'Y'">
      <span class="span-tit">*留学生归国日期</span
      ><el-input
        class="input"
        type="date"
        name=""
        v-model="resLogin.form['base-info'].DC_INTL_STD_DATE"
      /><span class="descr"></span>
    </div>
    <div class="input-box">
      <span class="span-tit">*是否申请宿舍</span
      ><el-select
        v-model="resLogin.form['base-info'].DC_APP_ROOM"
        placeholder="请选择"
        clearable
      >
        <el-option
          :value="option.fieldvalue"
          v-for="(option, index) in isList"
          :key="index"
          :label="option.xlatshortname"
        >
        </el-option></el-select
      ><span class="descr"></span>
    </div>
    <div class="input-box" v-if="resLogin.form['base-info'].DC_APP_ROOM == 'Y'">
      <span class="span-tit">*期望入住时间</span
      ><el-input
        class="input"
        type="date"
        name=""
        v-model="resLogin.form['base-info'].DC_ROOM_DATE"
      /><span class="descr"></span>
    </div>
    <div class="input-box" v-if="resLogin.form['base-info'].DC_APP_ROOM == 'Y'">
      <span class="span-tit">*申请住址</span
      ><el-select
        v-model="resLogin.form['base-info'].DC_ROOM_ADRESS2"
        placeholder="请选择"
        clearable
      >
        <el-option
          :value="option.fieldvalue"
          v-for="(option, index) in selectList.appadress_list"
          :key="index"
          :label="option.xlatshortname"
        >
        </el-option></el-select
      ><span class="descr"></span>
    </div>
    <div class="input-box">
      <span class="span-tit">*户口类别</span
      ><el-select
        v-model="resLogin.form['base-info'].HUKOU_TYPE_CHN"
        placeholder="请选择"
        clearable
      >
        <el-option
          :value="option.hukou_type_chn"
          v-for="(option, index) in selectList.hukou_type"
          :key="index"
          :label="option.descr"
        >
          {{ option.descr }}
        </el-option></el-select
      ><span class="descr"></span>
    </div>
    <div class="input-box">
      <span class="span-tit">*户口所在地</span>
      <el-autocomplete
        v-model="searchText"
        :fetch-suggestions="querySearch"
        class="inline-input w-50"
        placeholder="支持输入查询"
        @select="handleSelect"
        :value-key="value"
        submit-on-enter
        clearable
      />
      <span class="descr"></span>
    </div>
    <div class="input-box">
      <span class="span-tit">工作地点</span>
      <el-select v-model="resLogin.form['base-info'].LOCATION" disabled>
        <el-option
          :value="option.location"
          v-for="(option, index) in selectList.city_list"
          :key="index"
          :label="option.descr"
        >
        </el-option
      ></el-select>
      <span class="descr"></span>
    </div>
    <div class="input-box">
      <span class="span-tit">政治面貌</span
      ><el-select
        v-model="resLogin.form['base-info'].POLITICAL_STA_CHN"
        placeholder="请选择"
        clearable
      >
        <el-option
          :value="option.fieldvalue"
          v-for="(option, index) in selectList.political_sta"
          :key="index"
          :label="option.xlatshortname"
        >
          {{ option.xlatshortname }}
        </el-option></el-select
      ><span class="descr"></span>
    </div>
    <div class="input-box">
      <span class="span-tit">加入日期</span
      ><el-input
        class="input"
        type="date"
        name=""
        v-model="resLogin.form['base-info'].START_DATE"
      /><span class="descr"></span>
    </div>
    <div class="input-box">
      <span class="span-tit">籍贯</span>
      <!-- <el-input
        class="input"
        type="text"
        v-model="resLogin.form['base-info'].NATIVE_PLACE_CHN"
        name=""
        maxlength="30"
        placeholder="请输入"
      /> -->
      <el-autocomplete
        v-model="searchText_jiguan"
        :fetch-suggestions="querySearch"
        class="inline-input w-50"
        placeholder="支持输入查询"
        @select="handleSelect_jiguan"
        :value-key="value"
        submit-on-enter
        clearable
      />
      <span class="descr"></span>
    </div>
    <div class="input-box">
      <span class="span-tit">*是否有语言技能</span
      ><el-select
        v-model="resLogin.form['base-info'].FLAG"
        placeholder="请选择"
        clearable
      >
        <el-option
          :value="option.fieldvalue"
          v-for="(option, index) in isList"
          :key="index"
          :label="option.xlatshortname"
        >
        </el-option></el-select
      ><span class="descr"></span>
    </div>
    <div class="input-box">
      <span class="span-tit">*是否有职称信息</span
      ><el-select
        v-model="resLogin.form['base-info'].FLAG1"
        placeholder="请选择"
        clearable
      >
        <el-option
          :value="option.fieldvalue"
          v-for="(option, index) in isList"
          :key="index"
          :label="option.xlatshortname"
        >
        </el-option></el-select
      ><span class="descr"></span>
    </div>
  </form>
</template>
<script>
import { useStore } from "vuex";
import { ref, reactive, toRefs, onMounted, watch } from "vue";
import { computed } from "vue";
import { common } from "../mixin/common";
import { request } from "../mixin/axios";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  name: "basicInfo",
  props: {},
  mixins: [common],
  setup() {
    // const loading = ref(false);
    const searchText = ref("");
    const searchText_jiguan = ref("");
    const chn = "CHN";
    const isList = ref([
      {
        xlatshortname: "是",
        fieldvalue: "Y",
      },
      {
        xlatshortname: "否",
        fieldvalue: "N",
      },
    ]);
    // 数据请求接口
    const urlLogin =
      "/api/PSIGW/RESTListeningConnector/PSFT_HR/GPS_PSP_INF.v1/";
    const { requestPost } = request.methods;

    const store = useStore();
    const fileName = ref("");
    const fileName_nid = ref("");
    const fileName_nidBack = ref("");
    //获取store中存储的信息
    const resLogin = computed(() => {
      return store.state.userInfo.resLogin;
    });
    const resLoginData = reactive({
      ...resLogin.value.form["base-info"],
    });
    //取值state
    const selectList = computed(() => {
      return store.state.myValue;
    });

    watch(
      () => resLogin.value.form["base-info"].DC_APP_ROOM,
      (newValue) => {
        if (newValue == "N") {
          resLogin.value.form["base-info"].DC_ROOM_DATE = "";
          resLogin.value.form["base-info"].DC_ROOM_ADRESS2 = "";
        }
      },
      { deep: true }
    );
    watch(
      () => resLogin.value.form["base-info"].DC_INTL_STD,
      (newValue) => {
        if (resLogin.value.form["base-info"].DC_INTL_STD == "N") {
          resLogin.value.form["base-info"].DC_INTL_STD_DATE = "";
        }
      },
      { deep: true }
    );
    watch(
      () => resLogin.value.form["base-info"].YESNO_DROPDOWN,
      (newValue) => {
        if (resLogin.value.form["base-info"].YESNO_DROPDOWN == "Y") {
          resLogin.value.form["base-info"].END_DT = "";
        }
      },
      { deep: true }
    );
    watch(
      () => resLogin.value.form["base-info"].MAR_STATUS,
      (newValue) => {
        if (resLogin.value.form["base-info"].MAR_STATUS == "A" || resLogin.value.form["base-info"].MAR_STATUS == "O") {
          resLogin.value.form["base-info"].MAR_STATUS_DT = "";
        }
      },
      { deep: true }
    );
    onMounted(() => {
      // resLogin.value.form['base-info'].CONTRIB_AREA_CHN
      selectList.value.hukou_loction.filter((i) => {
        if (
          i.contrib_area_chn ==
          resLogin.value.form["base-info"].CONTRIB_AREA_CHN
        ) {
          searchText.value = i.descr;
        }
        if (i.descr == resLogin.value.form["base-info"].NATIVE_PLACE_CHN) {
          searchText_jiguan.value = i.descr;
        }
      });

      selectList.value.hukou_loction.forEach((ele) => {
        ele.value = ele.descr;
      });
      restaurants.value = loadAll(); //hukou_location列表
    });

    store.commit("setChildData", resLogin.value.form);

    watch(
      () => resLogin.value.form["base-info"].YESNO_DROPDOWN,
      (newVal) => {
        if (newVal == "N") {
          resLogin.value.form["base-info"].START_DT = "9999-12-31";
          resLogin.value.form["base-info"].END_DT = "9999-12-31";
        }
      }
    );

    const restaurants = ref([{}]);
    const querySearch = (queryString, cb) => {
      const results = queryString
        ? restaurants.value.filter((restaurant) => {
            return (
              restaurant.value
                .toLowerCase()
                .indexOf(queryString.toLowerCase()) != -1
            );
          })
        : restaurants.value;
      cb(results);
    };

    const loadAll = () => {
      return selectList.value.hukou_loction;
    };
    const handleSelect = (item) => {
      searchText.value = item.value;
      resLogin.value.form["base-info"].CONTRIB_AREA_CHN = item.contrib_area_chn;
    };
    const handelSelect_jiguan = (item) => {
      searchText_jiguan.value = item.value;
      resLogin.value.form["base-info"].NATIVE_PLACE_CHN = item.descr;
    };

    return {
      chn,
      resLogin,
      fileName,
      fileName_nid,
      fileName_nidBack,
      resLoginData,
      ...toRefs(resLoginData),
      selectList,
      isList,
      restaurants,
      querySearch,
      loadAll,
      handleSelect,
      handelSelect_jiguan,
      searchText,
      searchText_jiguan,
    };
    // console.log(store.state.userInfo.resLogin,"resLogin")
  },
};
</script>
<style scoped>
.form {
  font-size: var(--pc-text-size-title);
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  line-height: 45px;
  padding: 15px;
  /* overflow-y: scroll;*/
  overflow-x: hidden;
  /* padding-right: 50px; */

  .file-box {
    width: 100%;
  }

  .input-box {
    /* min-width: 100px; */
    width: calc(100% / 3);
    display: flex;
    flex-direction: row;
    align-items: center;

    .span-tit {
      width: 150px;
      /* display: flex;
      justify-content: end; */

      line-height: 17px;
    }

    /* input,
    select {
      width: 200px;
      border: 1px solid rgb(199, 199, 199);
      border-radius: 3px;
      font-size: 12px;
      margin-left: 5px;
      height: 35px;
    } */

    .descr {
      width: 195px;
      word-wrap: break-word;
      line-height: 17px;
      margin-left: 5px;
    }
  }
}

@media only screen and (max-width: 768px) {
  /* 手机端适配 */
  .form {
    gap: 10px;

    .input-box {
      gap: 10px;
      width: 100%;
      .span-tit {
        width: 40%;
      }

      .el-input__wrapper,
      select {
        width: 80% !important;
        height: 20px;
        font-size: 10px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  /* 平板电脑的样式 */
}

.upload-list-inline :deep(.ant-upload-list-item) {
  float: left;
  width: 200px;
  margin-right: 8px;
}

.upload-list-inline [class*="-upload-list-rtl"] :deep(.ant-upload-list-item) {
  float: right;
}
</style>
