<template>
  <div class="header">
    <!-- <slot name="logo"></slot>
<slot name="user"></slot> -->
    <img src="@/assets/科兴logo反白.svg" style="width: 200px" />
    <div
      style="display: flex; justify-content: center"
    >
      <UserOutlined
        type="search"
        style="font-size: 20px; color: white; margin: 0 5px 0 0"
      /><em style="color: white;">当前用户：<em style="text-decoration: underline;">{{ userName}}</em></em>
      <!-- {{ resLogin.form['base-info'].DC_NAME_50 }} -->
    </div>
  </div>
</template>
<script>
import { UserOutlined } from "@ant-design/icons-vue";
import { ref } from "vue";
import { useRoute } from "vue-router";

export default {
  components: {
    UserOutlined,
  },
  setup() {
    const route = useRoute();
    const resLo = ref('')
resLo.value = JSON.parse(route.query.resLogin)
const userName =ref('')
userName.value=`${resLo.value.form['base-info'].LAST_NAME}${resLo.value.form['base-info'].FIRST_NAME}`
    return {
      userName
    }
  },
};
</script>
<style scoped>
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 10vh;
  background-color: var(--header-bg-color);
  padding: 0 10px 0 10px;
  justify-content: space-between;
  gap: 10px;
}
</style>
