<template>
  <!-- <el-input type="text" readonly hidden/>用于存序号，需不需要这种方式存还待定-->
  <a-tabs v-model:activeKey="activeKey" type="editable-card" @edit="onEdit" @change="handleTabChange">
    <a-tab-pane
      v-for="(pane, ind) in panes"
      :key="pane.key"
      :tab="pane.title"
      :closable="pane.closable"
    >
      <form class="form">
        <div style="width: 100%; color: red">提醒：实习经历不填写</div>
        <div class="input-box">
          <span class="span-tit">*开始日期</span
          ><el-input
            type="date"
            v-model="resLogin.form['work-exp'][ind].START_DT"
          /><span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">*结束日期</span
          ><el-input
            type="date"
            v-model="resLogin.form['work-exp'][ind].END_DT"
          /><span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">*公司名称</span
          ><el-input
            type="text"
            maxlength="30"
            v-model="resLogin.form['work-exp'][ind].EMPLOYER"
            placeholder="请输入"

          /><span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">公司性质</span
          ><el-select
            v-model="resLogin.form['work-exp'][ind].DC_COMPANY_NATURE"
            placeholder="请选择"
            clearable
          >
            <el-option
              :value="option.fieldvalue"
              v-for="(option, index) in selectList.company_nature"
              :key="index"
              :label="option.xlatshortname"
            >
            </el-option></el-select
          ><span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">所属行业</span>
          <el-input
            type="text"
            maxlength="20"
            v-model="resLogin.form['work-exp'][ind].DC_INDUSTRY"
            placeholder="请输入"

          />
          <span class="descr"></span>
        </div>

        <div class="input-box">
          <span class="span-tit">所在国家</span>
          <!-- <el-select value="" v-model="resLogin.form['work-exp'][ind].COUNTRY">
            <el-option
              :value="option.country"
              v-for="(option, index) in selectList.country"
              :key="index"
            >
              {{ option.descr }}
            </el-option></el-select> -->
            <el-autocomplete
            v-model="searchText[tabsIndex]"
            :fetch-suggestions="querySearch"
            class="inline-input w-50"
            placeholder="支持输入查询"
            @select="handleSelect(ind,$event)"
            :value-key="value"
            submit-on-enter
            clearable
          />
            <span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">地址</span>
          <el-input
            type="text"
            maxlength="100"
            v-model="resLogin.form['work-exp'][ind].ADDRESS100"
            placeholder="请输入"

          />
          <span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">部门</span>
          <el-input
            type="text"
            maxlength="128"
            v-model="resLogin.form['work-exp'][ind].DEPT_DESCR"
            placeholder="请输入"

          />
          <span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">*担任岗位</span>
          <el-input
            type="text"
            maxlength="30"
            v-model="resLogin.form['work-exp'][ind].ENDING_TITLE"
            placeholder="请输入"

          />
          <span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">薪资范围</span>
          <el-input
            type="text"
            maxlength="15"
            v-model="resLogin.form['work-exp'][ind].DC_SALARY_RANGE"
            placeholder="请输入"

          />
          <span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">币种</span>
            <!-- <el-autocomplete
            v-model="searchText1[tabsIndex]"
            :fetch-suggestions="querySearch1"
            class="inline-input w-50"
            placeholder="支持输入查询"
            @select="handleSelect1(ind,$event)"
            :value-key="value"
            submit-on-enter
            clearable
            
          /> -->
          <el-select
            placeholder="请选择"
            v-model="resLogin.form['work-exp'][ind].CURRENCY_CD"
            clearable
          >
            <el-option
              :value="option.currency_cd"
              v-for="(option, index) in selectList.currency_list"
              :key="index"
              :label="option.descr"
              :default="cny"
            >
            </el-option>
          </el-select>
            <span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">证明人</span>
          <el-input
            type="text"
            maxlength="10"
            v-model="resLogin.form['work-exp'][ind].DC_CERTIFIER"
            placeholder="请输入"

          />
          <span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">证明人电话</span>
          <el-input
            type="text"
            maxlength="24"
            v-model="resLogin.form['work-exp'][ind].PHONE"
            placeholder="请输入"

          />
          <span class="descr"></span>
        </div>
        <div class="input-box" >
          <span class="span-tit">*工作内容/描述</span>
          <el-mention
            maxlength="254"
            style="resize:horizontal;"
            v-model="resLogin.form['work-exp'][ind].DC_WORK_CONTENT"
            type="textarea"
          ></el-mention>
          <span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">离职原因</span>
          <el-mention
            maxlength="254"
            style="resize:horizontal; "
            v-model="resLogin.form['work-exp'][ind].DC_TER_REASON"
            type="textarea"
          ></el-mention>

          <span class="descr"></span>
        </div>
        <div class="input-box" v-if="false">
          <span class="span-tit">是否实习经历</span
          ><el-select
          placeholder="请选择"
                      v-model="resLogin.form['work-exp'][ind].DC_IS_INTERSHIP_EX"
                      clearable
          >
            <el-option
              :value="option.fieldvalue"
              v-for="(option, index) in isList"
              :key="index"
              :label="option.xlatshortname"
            >
            </el-option></el-select
          ><span class="descr"></span>
        </div>
      </form>
    </a-tab-pane>
  </a-tabs>
</template>
<script>
import { useStore } from "vuex";
import { ref,onMounted} from "vue";
import { computed } from "vue";
export default {
  name: "family",
  props: {},
  setup() {
    const isList = ref([
      {
        xlatshortname: "是",
        fieldvalue: "Y",
      },
      {
        xlatshortname: "否",
        fieldvalue: "N",
      },
    ]);
    const cny = "CNY"
    const panes = ref([]);
    const store = useStore();

    const searchText = ref([]);
    const searchText1 = ref([]);
    //取值state
    const resLogin = computed(() => {
      return store.state.userInfo.resLogin;
    });
    //取值state
    const selectList = computed(() => {
      return store.state.myValue;
    });
    // layui标签页
    let index = 1; //工作经历编号
    // ============根据返回的数据看数组的长度在页面上遍历页签，空数组的话就自动生成一个页签==
    if (resLogin.value.form["work-exp"].length == 0) {
      panes.value.push({
        title: `工作经历${index}`,
        content: "",
        key: `${index}`,
      });
      index = index + 1;
    } else {
      //遍历所有数据，有几条数据就生成几个tab
      for (let i = 0; i < resLogin.value.form["work-exp"].length; i++) {
        if(i==0){
        panes.value.push({
          title: `工作经历${index}`,
          content: "",
          key: `${index}`,
          closable:false

        });
      }else{
        panes.value.push({
          title: `工作经历${index}`,
          content: "",
          key: `${index}`,
        });

      }
        index = index + 1;
      }
    }
    //=================

    const activeKey = ref(panes.value[0].key);
    const newTabIndex = ref(0);
    //添加页签
    const add = () => {
      let work_exp = {
        DC_CANDDT_ID: resLogin.value.form["base-info"].DC_CANDDT_ID,
        SEQUENCE_NBR: "",
        START_DT: "",
        END_DT: "",
        EMPLOYER: "",
        DC_COMPANY_NATURE: "",
        DC_INDUSTRY: "",
        COUNTRY: "",
        ADDRESS100: "",
        DEPT_DESCR: "",
        ENDING_TITLE: "",
        DC_SALARY_RANGE: "",
        CURRENCY_CD: "",
        DC_CERTIFIER: "",
        PHONE: "",
        DC_WORK_CONTENT: "",
        DC_TER_REASON: "",
        DC_IS_INTERSHIP_EX: "",
      };
      activeKey.value = index;
      panes.value.push({
        title: `工作经历${index}`,
        content: "",
        key: activeKey.value,
      });
      resLogin.value.form["work-exp"].push(work_exp);
      console.log(resLogin.value.form["work-exp"]);
      index = index +1;

      // resLogin.value.form["relative"][index].DEPENDENT_BENEF = `${index}`;
      store.dispatch("userInfo/setUserData", resLogin);
    };
    //删除页签
    const remove = (targetKey) => {
      let lastIndex = 0;
      panes.value.forEach((pane, i) => {
        if (pane.key === targetKey) {
          lastIndex = i - 1;
        }
      });
      panes.value = panes.value.filter((pane) => pane.key !== targetKey);
      if (panes.value.length && activeKey.value === targetKey) {
        if (lastIndex >= 0) {
          activeKey.value = panes.value[lastIndex].key;
        } else {
          activeKey.value = panes.value[0].key;
        }
      }
      index = index -1 ;
      resLogin.value.form["work-exp"].splice(targetKey-1, 1);

      store.dispatch("userInfo/setUserData", resLogin);
    };
    const onEdit = (targetKey, action) => {
      console.log("on", targetKey);

      if (action === "add") {
        add();
      } else {
        remove(targetKey);
      }
    };
    store.commit("setChildData", resLogin.value.form);


    const tabsIndex = ref(0);
    const handleTabChange = (key, event) => {
      tabsIndex.value = key - 1;
      console.log(tabsIndex.value)
      getData()
      getData1()
    };

    const getData = ()=> {
      selectList.value.country.filter((i) => {
        if ( i.country == resLogin.value.form["work-exp"][tabsIndex.value].COUNTRY) {
          searchText.value[tabsIndex.value] = i.descr;
        }
      });
     }
     const getData1 = ()=> {
      selectList.value.currency_list.filter((i) => {
        if ( i.currency_cd == resLogin.value.form["work-exp"][tabsIndex.value].CURRENCY_CD) {
          searchText1.value[tabsIndex.value] = i.descr;
        }
      });
     }
     onMounted(() => {
      // resLogin.value.form['base-info'].CONTRIB_AREA_CHN
      getData()
      getData1()
      selectList.value.country.forEach((ele) => {
        ele.value = ele.descr;
      });
      restaurants.value = loadAll();

      selectList.value.currency_list.forEach((ele) => {
        ele.value = ele.descr;
      });
      restaurants1.value = loadAll1();
    });

    const restaurants = ref([{}]);
    const restaurants1= ref([{}]);

    const querySearch = (queryString, cb) => {
      const results = queryString
        ? restaurants.value.filter((option) => option.value.includes(queryString))
          : restaurants.value;
      cb(results);
    };

    const querySearch1 = (queryString, cb) => {
      const results = queryString
        ? restaurants1.value.filter((option) => option.value.includes(queryString))
          : restaurants1.value;
      cb(results);
    };
    const loadAll = () => {
      return selectList.value.country;
    };

    const loadAll1 = () => {
      return selectList.value.currency_list;
    };
    const handleSelect = (index,item) => {
      console.log(item,"======",index)
      searchText.value[index] = item.value;
      resLogin.value.form["work-exp"][index].COUNTRY = item.country;
    };
    const handleSelect1 = (index,item) => {
      console.log(item,"======",index)
      searchText1.value[index] = item.value;
      resLogin.value.form["work-exp"][index].CURRENCY_CD = item.currency_cd;
    };
    return {
      resLogin,
      index,

      add,
      remove,
      onEdit,
      panes,
      selectList,
      isList,
cny,
      restaurants,
      querySearch,
      loadAll,
      handleSelect,
      searchText,
      getData,
      handleTabChange,
      tabsIndex,
      searchText1,
      getData1,
      querySearch1,
      loadAll1,
      handleSelect1,
    };
    // console.log(store.state.userInfo.resLogin,"resLogin")
  },
};
</script>
<style scoped>
/* 自己的样式 */

.form {
  font-size: var(--pc-text-size-title);
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  line-height: 45px;
  padding: 15px;
  /* padding-right: 50px; */

  .file-box {
    width: 100%;
  }
  .input-box {
    /* min-width: 100px; */
    width: calc(100% / 3);
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 3px 0 3px 0;
    .span-tit {
      width: 150px;
      /* display: flex;
      justify-content: end; */
      line-height: 17px;
    }
    input,
    select,
    .el-autocomplete,
    .el-textarea__inner {
      /* width: 200px !important; */
      border: 1px solid rgb(199, 199, 199) !important;
      height: 25px !important;
      border-radius: 3px !important;
      font-size: 12px !important;
      margin-left: 5px !important;
      /* -webkit-appearance:unset !important ; */
      option {
        height: 25px;
      }
    }

    .descr {
      width: 195px;
      word-wrap: break-word;
      line-height: 17px;
      margin-left: 5px;
    }
  }
}
@media only screen and (max-width: 768px) {
  /* 手机端适配 */
  .form {
    gap: 10px;
    .input-box {
      gap: 10px;
      width: 100%;
      .span-tit {
        width: 40%;
      }
      input,
      select {
        width: 80%;
        height: 20px;
        font-size: 10px;
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  /* 平板电脑的样式 */
}
</style>
