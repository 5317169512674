<template>
    <div class="content-box" style=" height: 100vh; width: 100%;text-align: center;">
        <h1 style="color: blue;font-size: 2.5em;">信息已填写完成，请退出</h1>
    </div>
</template>
<script setup>
import { ElMessageBox } from "element-plus";
ElMessageBox.alert("提交成功", "成功", {
      confirmButtonText: "确认",
    });
</script>
<style scoped>
.content-box{
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../assets/登录页背景.png');
    background-position: center;
    background-size: cover;
}
@media only screen and (max-width: 768px) {
  /* 手机端适配 */
  .content-box{
  background-image: url('@/assets/bg-phone.png');
  background-size: cover;
  background-position: center;
}
}
</style>