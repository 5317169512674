<template>
<!-- <el-input type="text" readonly hidden/>用于存序号，需不需要这种方式存还待定-->
  <a-tabs
    v-model:activeKey="activeKey"
    type="editable-card"
    @edit="onEdit"
    @change="handleTabChange"
  >
    <a-tab-pane
      v-for="(pane, ind) in panes"
      :key="pane.key"
      :tab="pane.title"
      :closable="pane.closable"
    >
      <form class="form">
        <div style="width: 100%; color: red">提醒：在校生填写预计毕业时间</div>
        <div class="input-box">
          <span class="span-tit">*毕业院校</span>
          <!-- <el-select value="" v-model="resLogin.form['edu-exp'][ind].SCHOOL">
            <el-option
              :value="option.school_code"
              v-for="(option, index) in selectList.school_list"
              :key="index"
              :label="option.descr"
            >
            </el-option></el-select
          > -->
          <el-autocomplete
            v-model="searchText[tabsIndex]"
            :fetch-suggestions="querySearch"
            class="inline-input w-50"
            placeholder="输入查询，若没有请选择“其他”"
            @select="handleSelect(ind, $event)"
            :value-key="value"
            submit-on-enter
            clearable
          />
          <span class="descr"></span>
        </div>
        <div class="input-box" v-if="resLogin.form['edu-exp'][ind].SCHOOL=='99'">
          <span class="span-tit">其他院校</span>
          <el-input placeholder="请输入院校名称" v-model="otherSchool[ind]"  @input="updateOtherSchool(ind,$event)">
          </el-input>
          <span class="descr"></span>
        </div>

        <div class="input-box">
          <span class="span-tit">院校类别</span>
          <el-input placeholder="请输入院校名称" v-model="school_tp" disabled>
          </el-input>
          <!-- <el-select
            placeholder="请选择"
            v-model="school_tp"
            clearable
          >
            <el-option
              :value="option.fieldvalue"
              v-for="(option, index) in selectList.school_type"
              :key="index"
              :label="option.xlatshortname"
            >
            </el-option>
          </el-select> -->
          <span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">*入学日期</span
          ><el-input
            type="date"
            v-model="resLogin.form['edu-exp'][ind].BEGIN_DT"
          /><span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">*毕业日期</span
          ><el-input
            type="date"
            v-model="resLogin.form['edu-exp'][ind].END_DT"
          /><span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">*专业</span
          ><el-input
            type="text"
            maxlength="30"
            v-model="resLogin.form['edu-exp'][ind].MAJOR"
            placeholder="无专业请输入“无”"
          /><span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">*专业类型</span>
          <el-select
            placeholder="请选择"
            v-model="resLogin.form['edu-exp'][ind].DC_MAJOR_TYPE"
            clearable
          >
            <el-option
              :value="option.fieldvalue"
              v-for="(option, index) in selectList.major_type"
              :key="index"
              :label="option.xlatshortname"
            >
            </el-option>
          </el-select>
          <span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">*学习形式</span>
          <el-select
            value=""
            v-model="resLogin.form['edu-exp'][ind].DC_LEARN_FORMS"
            placeholder="请选择"
            clearable
          >
            <el-option
              :value="option.fieldvalue"
              v-for="(option, index) in selectList.learn_forms"
              :key="index"
              :label="option.xlatshortname"
            >
            </el-option>
          </el-select>
          <span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">专业批次</span>
          <el-select
            placeholder="请选择"
            v-model="resLogin.form['edu-exp'][ind].DC_MAJOR_BATCH"
            clearable
          >
            <el-option
              :value="option.fieldvalue"
              v-for="(option, index) in selectList.major_batch"
              :key="index"
              :label="option.xlatshortname"
            >
            </el-option>
          </el-select>
          <span class="descr"></span>
        </div>

        <div class="input-box">
          <span class="span-tit">*学历</span>
          <el-select
            placeholder="请选择学历,无学历选择“无学历”"
            v-model="resLogin.form['edu-exp'][ind].DC_EDU_EDUCATION"
            clearable
          >
            <el-option
              :value="option.fieldvalue"
              v-for="(option, index) in selectList.education_list"
              :key="index"
              :label="option.xlatshortname"
            >
            </el-option>
          </el-select>
          <span class="descr"></span>
        </div>

        <div class="input-box">
          <span class="span-tit">是否第一学历</span>
          <el-select
            placeholder="请选择"
            v-model="resLogin.form['edu-exp'][ind].DC_IS_FIRST_EDU"
            clearable
          >
            <el-option
              :value="option.fieldvalue"
              v-for="(option, index) in selectList.ishighest_education"
              :key="index"
              :label="option.xlatshortname"
            >
            </el-option>
          </el-select>
          <span class="descr"></span>
        </div>

        <div class="input-box">
          <span class="span-tit">*是否最高学历</span>
          <el-select
            placeholder="请选择"
            v-model="resLogin.form['edu-exp'][ind].DC_IS_HIGH_EDU"
            clearable
          >
            <el-option
              :value="option.fieldvalue"
              v-for="(option, index) in selectList.ishighest_education"
              :key="index"
              :label="option.xlatshortname"
            >
            </el-option>
          </el-select>
          <span class="descr"></span>
        </div>

        <div class="input-box">
          <span class="span-tit">学历获取时间</span>
          <el-input
            type="date"
            v-model="resLogin.form['edu-exp'][ind].DC_GRADUATE_DATE"
          />
          <span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">*学历毕业证书号</span
          ><el-input
            type="text"
            maxlength="30"
            v-model="resLogin.form['edu-exp'][ind].DC_GRADUATE_NUMBER"
            placeholder="无证书请输入“无”"
          /><span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">*学位</span>
          <el-select
            placeholder="请选择学位,无学位请选择“无学位”"
            v-model="resLogin.form['edu-exp'][ind].DC_DEU_DEGREE"
            clearable
          >
            <el-option
              :value="option.fieldvalue"
              v-for="(option, index) in selectList.edu_degree"
              :key="index"
              :label="option.xlatshortname"
            >
            </el-option>
          </el-select>
          <span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">*是否最高学位</span>
          <el-select
            placeholder="请选择"
            v-model="resLogin.form['edu-exp'][ind].DC_IS_HIGH_DEGREE"
            clearable
          >
            <el-option
              :value="option.fieldvalue"
              v-for="(option, index) in selectList.ishighest_degree"
              :key="index"
              :label="option.xlatshortname"
            >
            </el-option>
          </el-select>
          <span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">*学位证书号</span>
          <el-input
            type="text"
            maxlength="30"
            v-model="resLogin.form['edu-exp'][ind].DC_DEGREE_NUMBER"
            placeholder="无证书请输入“无”"
          />
          <span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">学位获取时间</span
          ><el-input
            type="date"
            v-model="resLogin.form['edu-exp'][ind].DC_DEGREE_DATE"
          /><span class="descr"></span>
        </div>
      </form>
    </a-tab-pane>
  </a-tabs>
</template>
<script>
import { useStore } from "vuex";
import { ref, onMounted, watch } from "vue";
import { computed } from "vue";
import { request } from "../mixin/axios";
export default {
  name: "family",
  props: {},
  setup() {
    const searchText = ref([]);
    const panes = ref([]);
    const store = useStore();
    const school_tp = ref("");
    //取值state
    const resLogin = computed(() => {
      return store.state.userInfo.resLogin;
    });
    //取值state
    const selectList = computed(() => {
      return store.state.myValue;
    });
    const otherSchool = computed(()=>{
    
        return store.state.otherSchool;
    });
const updateOtherSchool = (index,value)=>{
  
store.dispatch('setOtherSchool',{index,value});
}
    // layui标签页
    let index = 1; //教育信息编号
    // ============根据返回的数据看数组的长度在页面上遍历页签，空数组的话就自动生成一个页签==
    if (resLogin.value.form["edu-exp"].length == 0) {
      panes.value.push({
        title: `教育信息${index}`,
        content: "",
        key: `${index}`,
      });
      index = index + 1;
    } else {
      //遍历所有数据，有几条数据就生成几个tab
      for (let i = 0; i < resLogin.value.form["edu-exp"].length; i++) {
        if (i == 0) {
          panes.value.push({
            title: `教育信息${index}`,
            content: "",
            key: `${index}`,
            closable: false,
          });
        } else {
          panes.value.push({
            title: `教育信息${index}`,
            content: "",
            key: `${index}`,
          });
        }
        index = index + 1;
      }
    }
    //=================

    const activeKey = ref(panes.value[0].key);
    const newTabIndex = ref(0);
    //添加页签
    const add = () => {
      let edu_exp = {
        DC_CANDDT_ID: resLogin.value.form["base-info"].DC_CANDDT_ID,
        SEQNBR: "",
        BEGIN_DT: "",
        END_DT: "",
        SCHOOL: "",
        DC_SCHLLO_TYPE: "",
        DC_LEARN_FORMS: "",
        MAJOR: "",
        DC_MAJOR_TYPE: "",
        DC_EDU_EDUCATION: "",
        DC_MAJOR_BATCH: "",
        DC_CONNECT_FORM: "",
        DC_IS_HIGH_EDU: "",
        DC_GRADUATE_NUMBER: "",
        DC_GRADUATE_DATE: "",
        DC_DEU_DEGREE: "",
        DC_IS_HIGH_DEGREE: "",
        DC_DEGREE_NUMBER: "",
        DC_DEGREE_DATE: "",
      };
      activeKey.value = index;
      panes.value.push({
        title: `教育信息${index}`,
        content: "",
        key: activeKey.value,
      });
      resLogin.value.form["edu-exp"].push(edu_exp);
      console.log(resLogin.value.form["edu-exp"]);
      index = index + 1;
      // resLogin.value.form["relative"][index].DEPENDENT_BENEF = `${index}`;
      store.dispatch("userInfo/setUserData", resLogin);
    };
    //删除页签
    const remove = (targetKey) => {
      let lastIndex = 0;
      panes.value.forEach((pane, i) => {
        if (pane.key === targetKey) {
          lastIndex = i - 1;
        }
      });
      panes.value = panes.value.filter((pane) => pane.key !== targetKey);
      if (panes.value.length && activeKey.value === targetKey) {
        if (lastIndex >= 0) {
          activeKey.value = panes.value[lastIndex].key;
        } else {
          activeKey.value = panes.value[0].key;
        }
      }
      index = index - 1;
      resLogin.value.form["edu-exp"].splice(targetKey - 1, 1);

      store.dispatch("userInfo/setUserData", resLogin);
    };
    const onEdit = (targetKey, action) => {
      console.log("on", targetKey);

      if (action === "add") {
        add();
      } else {
        remove(targetKey);
      }
    };
    store.commit("setChildData", resLogin.value.form);
    const tabsIndex = ref(0);
    const handleTabChange = (key, event) => {
      tabsIndex.value = key - 1;
      // selectData();
      getData();
    };
    const getData = () => {

      selectList.value.school_list.filter((i) => {
        if (
          i.school_code ==
          resLogin.value.form["edu-exp"][tabsIndex.value].SCHOOL
        ) {
          searchText.value[tabsIndex.value] = i.descr;

        }
      });
    };

    onMounted(() => {
      let isOtherSchool;
   
      selectList.value.school_list.forEach((ele) => {
        ele.value = ele.descr;
      });
      restaurants.value = loadAll();//school_list
      for(let i =0;i<resLogin.value.form['edu-exp'].length;i++){
      if(resLogin.value.form['edu-exp'][i].SCHOOL){
        isOtherSchool = restaurants.value.some(item=>item.school_code===resLogin.value.form['edu-exp'][i].SCHOOL)
        if(!isOtherSchool){
          //学校列表中不存在用户提供的学校时，将学校自动置为--其他--
          console.log('school:', resLogin.value.form['edu-exp'][i].SCHOOL);
          console.log('other:',otherSchool.value);
          
          
         otherSchool.value[i] = resLogin.value.form['edu-exp'][i].SCHOOL
        // store.dispatch('setOtherSchool',{i,sch});

        resLogin.value.form['edu-exp'][i].SCHOOL = '99'

        }
      }

    }
    getData();

    });
    const restaurants = ref([{}]);
    const querySearch = (queryString, cb) => {
      const results = queryString
        ? restaurants.value.filter((option) =>
            option.value.includes(queryString)
          )
        : restaurants.value;
      cb(results);
    };
    const loadAll = () => {
      return selectList.value.school_list;
    };
    const handleSelect = (index, item) => {
      console.log(item, "======", index);
      searchText.value[index] = item.value;
      resLogin.value.form["edu-exp"][index].SCHOOL = item.school_code;
      school_tp.value = item.dc_schllo_type

      //选择其他时候的逻辑
      // if (item.school_code == "99") {
        
      // }
    };









        // selectList.value.school_list.map((item) => {
    //   if (
    //     item.school_code ==
    //     resLogin.value.form["edu-exp"][activeKey.value - 1].school_code
    //   ) {

    //     quacate.value[activeKey.value - 1] = item.dc_qualificate == "10-准入类" ? "10" : "20";
    //   }
    // });

    // watch(
    //   () =>
    //     resLogin.value.form["professional-qual"][activeKey.value - 1].DC_PRQUID,
    //   (newVal) => {
    //     console.log(newVal, "newValnewValnewValnewValnewVal1111");
    //     selectList.value.prqu_list.map((item) => {
    //       if (
    //         item.dc_prquid ==
    //         resLogin.value.form["professional-qual"][activeKey.value - 1]
    //           .DC_PRQUID
    //       ) {
    //         resLogin.value.form["professional-qual"][
    //           activeKey.value - 1
    //         ].DC_QUALIFICATE = item.dc_qualificate == "10-准入类" ? "10" : "20";
    //       }
    //     });
    //   }
    // );
    return {
      resLogin,
      index,
      otherSchool,
      add,
      remove,
      onEdit,
      panes,
      selectList,
      updateOtherSchool,

      handleTabChange,
      tabsIndex,
      restaurants,
      querySearch,
      loadAll,
      handleSelect,
      searchText,
      getData,
      school_tp
    };
    // console.log(store.state.userInfo.resLogin,"resLogin")
  },
};
</script>
<style scoped>
/* 自己的样式 */

.form {
  font-size: var(--pc-text-size-title);
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  line-height: 45px;
  padding: 15px;
  /* padding-right: 50px; */

  .file-box {
    width: 100%;
  }
  .input-box {
    /* min-width: 100px; */
    width: calc(100% / 3);
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 3px 0 3px 0;
    .span-tit {
      width: 150px;
      /* display: flex;
      justify-content: end; */
      line-height: 17px;
    }
    input,
    select {
      width: 200px;
      border: 1px solid rgb(199, 199, 199);
      height: 25px;
      border-radius: 3px;
      font-size: 12px;
      margin-left: 5px;
      /* -webkit-appearance:unset !important ; */
      option {
        height: 25px;
      }
    }

    .descr {
      width: 195px;
      word-wrap: break-word;
      line-height: 17px;
      margin-left: 5px;
    }
  }
}
@media only screen and (max-width: 768px) {
  /* 手机端适配 */
  .form {
    gap: 10px;
    .input-box {
      gap: 10px;
      width: 100%;
      .span-tit {
        width: 40%;
      }
      input,
      select {
        width: 80%;
        height: 20px;
        font-size: 10px;
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  /* 平板电脑的样式 */
}
</style>
